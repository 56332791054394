<template>
    <div class="container">
        <div class="flex space-x-4 items-center py-5">
            <img src="/images/logo.png" alt="Happy box Prenatal" class="h-16 md:h-[120px]" />
            <div>
                <span class="text-brand-gray font-light text-xs md:text-base pl-1 md:pl-2">un progetto di</span>
                <a href="https://www.prenatal.com/?utm_source=sito&utm_medium=landing&utm_campaign=happybox_logopnt" target="_blank">
                    <img src="/images/prenatal-logo.png" class="h-5 md:h-10" alt="Prenatal" />
                </a>
            </div>
        </div>
    </div>
</template>