<template>
    <div class="w-full bg-primary text-white py-12">
        <div class="container flex space-x-5 flex-col lg:flex-row items-center justify-between">
            <a href="https://www.prenatal.com/?utm_source=sito&utm_medium=landing&utm_campaign=happybox_logopnt"
                target="_blank">
                <img src="/images/prenatal-logo-white.png" class="h-8" alt="Prenatal" />
            </a>
            <p class="font-light text-sm text-center mt-5 lg:mt-0">© 2023 Prénatal S.p.A. - P.IVA 00857680151 <a
                    href="/privacy-policy">Privacy Policy</a> <a target="_blank"
                    href="https://www.prenatal.com/cookies">Cookie Policy</a> <a href="/terms">Termini e Condizioni</a>
            </p>
            <div class="flex space-x-5 items-center mt-10 lg:mt-0">
                <p class="font-medium leading-tight">Condividi con<br> un’amica</p>
                <div class="bg-white rounded-full flex items-center justify-center w-14 h-14 flex-shrink-0 cursor-pointer"
                    @click="modalStore.showModal()">
                    <img src="/icons/share.svg" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const modalStore = useModalStore();
</script>